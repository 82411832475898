<!-- text -->
<div class="form-filed-wrapper" *ngIf="isTextType">
	<mat-form-field appearance="outline">
		<mat-label>{{label}}</mat-label>
		<input cdkFocusInitial #field name="input" data-test-id="one-value-text-input"
			   matInput [required]="required" type="text" [formControl]="fieldControl" [readonly]="readonly"
			   (keyup)="onChange(field.value)" [errorStateMatcher]="evaluateAfterChange? matcher: null">
		<button *ngIf="!readonly" type="button" class="clear-button" matSuffix mat-icon-button matTooltip="Eingabe löschen"
				[attr.aria-label]="'Im Eingabefeld '+ label"
				(click)="clearField(fieldControl,$event)" data-test-id="delete-button">
			<mat-icon>close</mat-icon>
		</button>
		<mat-error class="errorMessage">
			<span class="material-icons" *ngIf="fieldControl?.invalid">error</span>
			<span data-test-id="one-value-editor-error-message">{{errorMessages}}</span>
		</mat-error>
	</mat-form-field>
</div>

<!-- long text -->
<div class="form-filed-wrapper" *ngIf="isLongTextType" data-test-id="one-value-longtext-field">
	<mat-form-field appearance="outline" data-test-id="one-value-text-field">
		<mat-label>{{label}}</mat-label>
		<textarea #field cdkFocusInitial data-test-id="one-value-text-input" id="one-value-textarea-input" matInput [required]="required" [formControl]="fieldControl"
				  (ngModelChange)="onChange(field.value)"></textarea>
		<mat-error class="errorMessage">
			<span class="material-icons" *ngIf="fieldControl?.invalid">error</span>
			<span data-test-id="one-value-editor-error-message">{{errorMessages}}</span>
		</mat-error>
	</mat-form-field>
</div>

<!-- numeric -->
<div *ngIf="isNumberType">
	<mat-form-field appearance="outline">
		<mat-label>{{label}}</mat-label>
		<input #field cdkFocusInitial data-test-id="one-value-numeric-input" matInput type="number" min="0" step="{{stepsize}}" lang="de"
			   [formControl]="fieldControl" [readonly]="readonly" [required]="required"
			   (keyup)="onChange(field.value)">
		<mat-error class="errorMessage">
			<span class="material-icons" *ngIf="fieldControl?.invalid">error</span>
			<span data-test-id="one-value-error-pattern-number">{{errorMessages}}</span>
		</mat-error>
	</mat-form-field>
</div>

<!-- dropdown -->
<mat-form-field *ngIf="isSelectType" appearance="outline">
	<mat-label>{{label}}</mat-label>
	<mat-select *ngIf="!readonly" cdkFocusInitial class="select" [placeholder]="label" [required]="required" [formControl]="fieldControl"
				data-test-id="one-value-select-input"
				(selectionChange)="onChange($event.value)" [attr.aria-label]="label">
		<mat-option *ngFor="let value of values" [value]="value.value" [disabled]="value.disabled" class="focus-box-shadow-dark" data-test-id="one-value-select-value">
			{{value.label}}
		</mat-option>
	</mat-select>
	<input *ngIf="readonly" cdkFocusInitial #field name="input" data-test-id="one-value-select-input-readonly"
		   matInput [required]="required" type="text" [value]="getDisplayValue()" [readonly]="true">
	<mat-error class="errorMessage">
		<span class="material-icons" *ngIf="fieldControl?.invalid">error</span>
		<span data-test-id="one-value-editor-error-message">{{errorMessages}}</span>
	</mat-error>
</mat-form-field>

<!-- autocomplete input -->
<ng-container *ngIf="isAutocompleteType">
	<schir-int-client-autocomplete [initialFocus]="initialFocus" [label]="label" [control]="fieldControl" [values]="values"
								   data-test-id="one-value-autocomplete">
	</schir-int-client-autocomplete>
</ng-container>

<!-- datepicker -->
<div class="form-filed-wrapper" *ngIf="isDateType">
	<mat-form-field *ngIf="!readonly" appearance="outline">
		<mat-label>{{label}}</mat-label>
		<input cdkFocusInitial data-test-id="one-value-date-input" matInput [required]="required" [matDatepicker]="picker"
			   [formControl]="fieldControl" (dateChange)="onChange($event.value)">
		<mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Kalender öffnen" aria-label="Kalender öffnen"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
		<mat-error class="errorMessage" data-test-id="one-value-error-pattern-date">
			<span class="material-icons" *ngIf="fieldControl?.invalid">error</span>
			<span data-test-id="one-value-editor-error-message">"{{label}}" muss ein Datum sein</span>
		</mat-error>
	</mat-form-field>
	<mat-form-field *ngIf="readonly" appearance="outline">
		<mat-label>{{label}}</mat-label>
		<input cdkFocusInitial name="input" data-test-id="one-value-date-input"
			   matInput [required]="required" type="text" [value]="getDisplayValue()" [readonly]="true">
	</mat-form-field>
</div>

<!-- checkbox -->
<div *ngIf="isCheckboxType" class="checkbox">
	<mat-checkbox *ngIf="!readonly" data-test-id="one-value-checkbox-input" color="primary" [formControl]="fieldControl"
				  [ngClass]="hideBoxShadow ? '' : 'focus-box-shadow-dark'"
				  [required]="required" (change)="onChange($event.checked)">
		{{label}}
	</mat-checkbox>
	<mat-checkbox *ngIf="readonly" data-test-id="one-value-checkbox-input" color="primary" [formControl]="fieldControl"
				  [required]="required" aria-label="schreibgeschützt {{label}}"
				  [ngClass]="hideBoxShadow ? '' : 'focus-box-shadow-dark'"
				  #myself [disableRipple]="true" (click)="onlyGainFocus($event, myself)" (keyup.space)="$event.preventDefault()" class="readonly">
		{{label}}
	</mat-checkbox>
</div>
