<div class="c-button-bar c-button-bar--overlay">
	<schir-int-client-button-round *ifResource="verfahren; hasLink: linkRel.REGISTERBLATT_AMTLICHER_AUSZUG"
								   data-test-id="icon-certificate-button" fontelloIcon="bundesadler" toolTip="Amtlichen Auszug erstellen und herunterladen"
								   (click)="openAmtlichenAuszugErstellenDialog.emit()" popup="dialog" cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round *ifResource="verfahren; hasLink: linkRel.REGISTERBLATT_SCHIFFSZERTIFIKAT"
								   data-test-id="icon-award-button" fontelloIcon="award" toolTip="Schiffszertifikat erstellen und herunterladen"
								   (click)="downloadSchiffszertifikat.emit()" cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round *ifResource="verfahren; hasLink: linkRel.REGISTERBLATT_SCHIFFSBRIEF"
								   data-test-id="icon-schiffsbrief-button" fontelloIcon="award" toolTip="Schiffsbrief erstellen und herunterladen"
								   (click)="downloadSchiffsbrief.emit()" cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round *ngIf="hasRegisterBlatt()"
								   data-test-id="verfahren-detail-button" materialIcon="table_chart" toolTip="Registerblatt anzeigen"
								   (click)="openRegisterblatt.emit()" cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round #button *ifResource="verfahren; hasLink: linkRel.NOTIZ"
	                               data-test-id="open-notiz-dialog-button" materialIcon="notes" toolTip="Notiz bearbeiten"
								   (click)="openNotizDialog.emit()" popup="dialog" cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round #button *ifResource="verfahren; hasLink: linkRel.MARK_AS_ABGELEHNT"
								   data-test-id="abgelehnt-button" materialIcon="pan_tool" toolTip="Verfahren ablehnen"
								   (click)="markAsAbgelehnt.emit()" popup="dialog" cssClass="abgelehnt focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round #button *ifResource="verfahren; hasLink: linkRel.DELETE"
								   data-test-id="verfahren-loeschen-button" materialIcon="delete" toolTip="Verfahren löschen"
								   (click)="deleteVerfahren.emit()" cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
	<schir-int-client-button-round *ngIf="showAddUsignalButton()"
								   data-test-id="add-usignal" materialIcon="notification_important" toolTip="U-Sinal zuordnen"
								   (click)="assignUSignal.emit()" popup="dialog" cssClass="focus-box-shadow-dark">
	</schir-int-client-button-round>
</div>
