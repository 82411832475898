import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState, getApiRoot} from '@schir-int-client/api-root';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {
	LoadVerfahrenAktionStatistikAction,
	LoadVerfahrenAktionStatistikSuccessAction,
	MonthlyStatistikLoadedAction,
	StatistikActions,
	StatistikLoadedAction,
	ZaehlblattStatistikLoadedAction,
} from './statistik.actions';
import {StatistikService} from './statistik.service';
import {
	MonthlyStatistik,
	StatistikResource,
	VerfahrenAktionStatistik,
	ZaehlblattStatistikResource,
} from './statistik.model';
import {AdminRootResource, adminRootSelector, AdminState} from '@schir-int-client/admin-shared';

@Injectable()
export class StatistikEffects {
	constructor(
		private actions: Actions,
		private statistikService: StatistikService,
		private store: Store<AppState>,
		private adminStore: Store<AdminState>,
	) {}


	loadStatistik = createEffect(() => this.actions.pipe(
		ofType(StatistikActions.LOAD_STATISTIK),
		withLatestFrom(this.store.select(getApiRoot())),
		switchMap(([, apiRoot]) => {
			return this.statistikService.getStatistik(apiRoot).pipe(
				map((statistik: StatistikResource) => new StatistikLoadedAction(statistik)),
			);
		}),
	));


	loadMonthlyStatistik = createEffect(() => this.actions.pipe(
		ofType(StatistikActions.LOAD_MONTHLY_STATISTIK),
		withLatestFrom(this.adminStore.select(adminRootSelector)),
		switchMap(([, adminRoot]) => {
			return this.statistikService.loadMonthlyStatistik(adminRoot).pipe(
				map((statistik: MonthlyStatistik) => new MonthlyStatistikLoadedAction(statistik)),
			);
		}),
	));

	loadZaehlblattStatistik = createEffect(() => this.actions.pipe(
		ofType(StatistikActions.LOAD_ZAEHLBLATT_STATISTIK),
		withLatestFrom(this.store.select(adminRootSelector)),
		switchMap(([, adminRoot]) => {
			return this.statistikService.loadZaehlblattStatistik(adminRoot).pipe(
				map((statistik: ZaehlblattStatistikResource) => {
					return new ZaehlblattStatistikLoadedAction(statistik);
				}),
			);
		}),
	));

	loadVerfahrenAktionStatistik = createEffect(() => this.actions.pipe(
		ofType(StatistikActions.LOAD_VERFAHREN_AKTION_STATISTIK),
		withLatestFrom(this.store.select(adminRootSelector)),
		switchMap(([action, adminRoot]: [LoadVerfahrenAktionStatistikAction, AdminRootResource]) => {
			return this.statistikService.loadVerfahrenAktionStatistik(adminRoot, action.von, action.bis).pipe(
				map((statistik: VerfahrenAktionStatistik) => new LoadVerfahrenAktionStatistikSuccessAction(statistik)),
			);
		}),
	));
}
