import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@schir-int-client/api-root';
import {Observable} from 'rxjs';
import {
	LoadMonthlyStatistikAction,
	LoadStatistikAction,
	LoadVerfahrenAktionStatistikAction,
	LoadZaehlblattStatistikAction,
} from './statistik.actions';
import {
	monthlyStatistikSelector,
	statistikSelector,
	verfahrenAktionStatistikSelector,
	zaehlblattStatistikSelector,
} from './statistik.selectors';
import {
	MonthlyStatistik,
	StatistikResource,
	VerfahrenAktionStatistik,
	ZaehlblattStatistikResource,
} from './statistik.model';
import {Moment} from 'moment';

@Injectable({ providedIn: 'root' })
export class StatistikFacade {

	statistik: Observable<StatistikResource> = this.store.select(statistikSelector);
	monthlyStatistik: Observable<MonthlyStatistik> = this.store.select(monthlyStatistikSelector);
	zaehlblattStatistik: Observable<ZaehlblattStatistikResource> = this.store.select(zaehlblattStatistikSelector);
	verfahrenAktionStatistik: Observable<VerfahrenAktionStatistik> = this.store.select(verfahrenAktionStatistikSelector);

	constructor(private store: Store<AppState>) {}

	loadStatistik() {
		this.store.dispatch(new LoadStatistikAction());
	}

	loadMonthlyStatistik() {
		this.store.dispatch(new LoadMonthlyStatistikAction());
	}

	loadZaehlblattStatistik() {
		this.store.dispatch(new LoadZaehlblattStatistikAction());
	}

	loadVerfahrenAktionStatistik(von: Moment, bis: Moment) {
		this.store.dispatch(new LoadVerfahrenAktionStatistikAction(von, bis));
	}
}
