import {AufgabeMessages, AufgabenTyp, AufgabeTypStaticTaetigkeitLabel} from '@schir-int-client/aufgabe-shared';
import {VerfuegungResource, VerfuegungTyp} from '@schir-int-client/verfuegung-shared';
import {CreateAufgabeDialogData} from './create-aufgabe-dialog.model';
import {CreateAufgabeFormService} from './create-aufgabe.formservice';
import {addAriaAttributesToMatSelect, BaseEditorComponent} from '@schir-int-client/tech';
import {Observable} from 'rxjs';
import {VorlageFacade, VorlageResource} from '@schir-int-client/vorlage-shared';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {ApiRootFacade} from '@schir-int-client/api-root';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'schir-int-client-create-aufgabe-dialog',
	templateUrl: './create-aufgabe-dialog.component.html',
	styleUrls: ['./create-aufgabe-dialog.component.scss'],
	providers: [CreateAufgabeFormService],
})
export class CreateAufgabeDialogComponent extends BaseEditorComponent implements OnInit {

	verfuegung: VerfuegungResource;
	editMode: boolean;

	readonly aufgabenTypen = AufgabenTyp;
	readonly messages = AufgabeMessages;
	readonly formServiceClass = CreateAufgabeFormService;
	readonly aufgabeTypStaticTaetigkeitLabel = AufgabeTypStaticTaetigkeitLabel;

	vorlagen: Observable<VorlageResource[]>;
	isLFPR: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) private data: CreateAufgabeDialogData,
	            public formService: CreateAufgabeFormService,
	            public apiRootFacade: ApiRootFacade,
	            private vorlageFacade: VorlageFacade) {
		super();

		this.verfuegung = this.data.verfuegung;
		this.editMode = this.data.editMode;
		this.formService.controlAufgabeTyp.setValidators([Validators.required]);
		this.vorlagen = this.vorlageFacade.vorlagen;
		if (this.editMode) {
			this.formService.subscribeToAufgabe();
		}
		this.isLFPR = this.apiRootFacade.isLFPR;
	}

	get form(): UntypedFormGroup {
		return this.formService.form;
	}

	ngOnInit(): void {
		addAriaAttributesToMatSelect();
	}

	async submit(): Promise<boolean> {
		return this.formService.submit();
	}

	get validAufgabenTypen(): string[] {
		if (this.verfuegung.typ == VerfuegungTyp.KORRESPONDENZ) {
			return Object.keys(this.aufgabenTypen).filter(value => this.isLFPR == value.startsWith('LFPR_'));
		}

		return Object.keys(this.aufgabenTypen)
			.filter((value) => value !== AufgabenTyp.ANSCHREIBEN && this.isLFPR == value.startsWith('LFPR_'));
	}

	hasNoAufageTyp(): boolean {
		return this.formService.controlAufgabeTyp.value == null;
	}
}
