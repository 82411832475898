import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RouterModule, Routes} from '@angular/router';
import {adressverwaltungListRoute, AdressverwaltungModule} from '@schir-int-client/adressverwaltung';
import {PosteingangFacade, PosteingangSharedModule} from '@schir-int-client/posteingang-shared';
import {TechModule} from '@schir-int-client/tech';
import {VerfahrenModule} from '@schir-int-client/verfahren';
import {VorgangModule} from '@schir-int-client/vorgang';
import {PdfViewerComponent} from './pdf-viewer/pdf-viewer.component';
import {PosteingangBearbeitenComponent} from './posteingang-bearbeiten/posteingang-bearbeiten.component';
import {PosteingangDocumentListComponent} from './posteingang-document-list/posteingang-document-list.component';
import {PosteingangListComponent} from './posteingang-list/posteingang-list.component';

const routes: Routes = [
	{
		path: 'posteingangBearbeiten/:posteingangUri',
		component: PosteingangBearbeitenComponent,
		children: [
			adressverwaltungListRoute,
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		MatTableModule,
		MatButtonModule,
		MatGridListModule,
		MatInputModule,
		MatOptionModule,
		MatSelectModule,
		MatFormFieldModule,
		MatIconModule,
		MatDialogModule,
		FormsModule,
		TechModule,
		RouterModule.forChild(routes),
		PosteingangSharedModule,
		TechModule,
		VerfahrenModule,
		AdressverwaltungModule,
		VorgangModule,
		MatProgressSpinnerModule,
	],
	declarations: [
		PosteingangDocumentListComponent,
		PosteingangListComponent,
		PosteingangBearbeitenComponent,
		PdfViewerComponent,
	], // SCHIFF-2338: NICHT LÖSCHEN!  , PosteingangDocumentViewerComponent],
	exports: [
		PosteingangListComponent,
		PosteingangBearbeitenComponent,
	],
	providers: [PosteingangFacade],
})
export class PosteingangModule {}
